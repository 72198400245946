/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import QandA from '../../components/common/q-and-a';
import questionsAndAnswers from './consent-q-and-a';
import TryitAction from '../../components/signup/tryit-action';
import Card from '../../components/content/card';
import {Highlight} from '@signatu/common-react';
import * as ConsentReact from '@signatu/consent-react';
import {anonSubject} from '@signatu/consent';
import ConsentCarousel from './consent-carousel';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(ConsentCarousel), "\n", React.createElement(TryitAction), "\n", React.createElement(Card, {
    title: "Consent Requests",
    image: "icons/check.png"
  }, React.createElement(_components.p, null, "Write correct consent requests in our editor, record and track request versions, and compose consent requests with\nour SDK.")), "\n", React.createElement(Card, {
    image: "icons/apikey.png",
    title: "Developer Friendly",
    imagePos: "right"
  }, React.createElement(_components.p, null, "Integrate our React components, our Javascript SDK (works with blogs, too), or use the flexible REST API. Either\nway, we secure your consent data and keep it available 24/7. Feel free to edit the code in the black box below. Try\nto change the language code. It’s live code.")), "\n", React.createElement(Highlight, {
    language: "jsx",
    live: true,
    scope: {
      anonSubject,
      ...ConsentReact
    }
  }, `
<ConsentButtons consentTarget={{ subject: anonSubject(), target: "signatu.com/demo/buttons"}}>
I consent that ACME Inc. can send me weekly personalized emails for the purpose of offering me relevant products and services.
</ConsentButtons>
    `), "\n", React.createElement(Card, {
    image: 'icons/key.png',
    title: "Secure Consent Record"
  }, React.createElement(_components.p, null, "You can receive, record, store, track and prove user consent events that you can use from your websites and apps.\nSignatu secures a log of all Consent Events from your users, providing a full audit trail for GDPR compliance.")), "\n", React.createElement(Card, {
    image: 'icons/emailnotification.png',
    imagePos: "right",
    title: "Notifications"
  }, React.createElement(_components.p, null, "Signatu can send events via Webhooks, Amazon Simple Queue Service (SQS), and Microsoft Azure Service Bus. So you can\nintegrate other systems that you need for your business, such as CRM, Analytics, Marketing, and ERP.")), "\n", React.createElement(Card, {
    image: "icons/calendar.png",
    title: "Always available"
  }, React.createElement(_components.p, null, "We make sure that all the data is available for your app when you need it. All data is stored in accordance with EU\nGDPR in ISO 27001 certified data centers in the EU.")), "\n", React.createElement(Card, {
    image: "icons/authority.png",
    imagePos: "right",
    title: "Legitimate Interest"
  }, React.createElement(_components.p, null, "You can register and manage opt-out of legitimate interest with Signatu consent technology.")), "\n", React.createElement(_components.h2, {
    id: "questions-and-answers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#questions-and-answers",
    "aria-label": "questions and answers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Questions and answers"), "\n", React.createElement(QandA, {
    data: questionsAndAnswers
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
