import { createStyles, Theme, WithStyles, withStyles, Typography } from '@material-ui/core'
import React, { useState, FC } from 'react'
import classNames from 'classnames'
import { Markdown } from '@signatu/common-react'
import { colors } from '@material-ui/core'

export interface QuestionAndAnswer {
    question: string
    answer: string
}

export interface QandAProps extends WithStyles<typeof styles> {
    className?: string
    data: QuestionAndAnswer[]
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        lead: {
            display: 'inline-block',
            marginRight: theme.spacing(4),
            color: theme.palette.grey[300],
        },
        flexBlock: {
            display: 'flex',
        },
        questionBlock: {
            '&:hover $lead': {
                color: theme.palette.grey[500],
            },
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        question: {
            display: 'inline-flex',
            overflowWrap: 'normal',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        answerLead: {
            color: colors.green[500],
        },
        answer: {
            display: 'inline-flex',
            overflowWrap: 'normal',
            alignItems: 'center',
        },
    })

const QandA: FC<QandAProps> = props => {
    const { classes, className, data } = props
    const [showMap, setShowMap] = useState<{ [idx: number]: boolean }>({})
    const showHide = (idx: number) => setShowMap({ ...showMap, [idx]: !showMap[idx] })
    return (
        <div className={classNames(className, classes.root)}>
            <Typography variant="caption">Click to see the answer.</Typography>
            {data.map(({ question, answer }, idx) => (
                <div key={idx} className={classes.questionBlock}>
                    <div className={classes.flexBlock} onClick={() => showHide(idx)}>
                        <Typography variant="h6" className={classes.lead}>
                            Q
                        </Typography>
                        <Typography variant="subtitle1" className={classes.question}>
                            {question}
                        </Typography>
                    </div>
                    {showMap[idx] && (
                        <div className={classes.flexBlock}>
                            <Typography variant="h6" className={classNames(classes.lead, classes.answerLead)}>
                                A
                            </Typography>
                            <Typography variant="body2" className={classes.answer}>
                                {answer}
                            </Typography>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default withStyles(styles)(QandA)
