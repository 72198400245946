import { QuestionAndAnswer } from '../../components/common/q-and-a'

const questions: QuestionAndAnswer[] = [
    {
        question: 'Can I change a Consent Event?',
        answer: 'No. Consent Events are immutable, providing an audit trail for GDPR compliance.',
    },
    {
        question: 'Does Signatu’s consent event record document consent as required by GDPR Article 7.1?',
        answer: 'Yes.',
    },
    {
        question: 'Can Signatu help me specify my consent request?',
        answer:
            'Yes. You can specify your consent request in our tool named Data Processing Specification. To use it, you need to have an account.',
    },
    {
        question: 'Can I style Signatu’s embeddable consent dialogue designs?',
        answer: 'Yes.',
    },
    {
        question:
            'Can I use Signatu’s consent event record without using Signatu’s Data Processing Specification and Signatu’s embeddable consent dialogue designs?',
        answer: 'Yes.',
    },
    {
        question: 'Can I use Signatu’s consent event record to record end users’ opt out of legitimate interest?',
        answer: 'Yes.',
    },
    {
        question:
            'Can I use Signatu’s Data Processing Specification to specify other legal grounds than consent (to process personal data)?',
        answer: 'Yes.',
    },
    {
        question: 'How do I request consent to use another company’s resources?',
        answer:
            'Use Trackerdetect to discover other companies on sites automatically and use Consent to request consent to use other company’s resources.',
    },
    {
        question: 'Can I prove that my consent request was provided at a URL at a given time?',
        answer:
            'Yes. Use Trackerdetect to scan and make a screenshot of the URL daily. Screenshots are recorded and stored.',
    },
    {
        question: 'Can I change a consent request that I have published in Signatu’s Data Processing Specification?',
        answer:
            'Yes. You can make a new consent request version. All previously published consent requests saved and are immutable.',
    },
    {
        question: 'Can I prove which information I provided in a published consent request at a given time?',
        answer:
            'Yes. All published and immutable consent requests are recorded and stored with a time stamp of the date of publication.',
    },
    {
        question: 'Does Signatu offer embeddable consent dialogue designs in multiple languages?',
        answer: 'Yes.',
    },
    {
        question: 'Must my consent requests be present in my Privacy Policy/Notice?',
        answer:
            'Yes. In the Policy generator, you can integrate your consent requests that you have specified in the tool for Data Processing Specification.',
    },
]

export default questions
