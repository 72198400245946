import { useSignatuSelector } from '@signatu/models-redux'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import React, { FC } from 'react'
import classNames from 'classnames'
import { Fab } from '@material-ui/core'
import { navigate } from 'gatsby'

export interface TryItActionProps extends WithStyles<typeof styles> {
    className?: string
    includeSignup?: boolean
    includePlans?: boolean
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        action: {
            margin: theme.spacing(4),
        },
    })

const TryItAction: FC<TryItActionProps> = (props) => {
    const { classes, className, includePlans = true, includeSignup = false } = props
    const isLoggedIn = useSignatuSelector((state) => state.account.isLoggedIn)

    /** Hide component if logged in */
    if (isLoggedIn) {
        return null
    }

    return (
        <div className={classNames(className, classes.root)}>
            {includeSignup && (
                <Fab
                    variant="extended"
                    size="large"
                    className={classes.action}
                    onClick={() => navigate('/app/signup')}
                    color="secondary"
                    aria-label="tryit"
                >
                    {/* <NavigationIcon className={classes.extendedIcon} /> */}
                    Try Signatu
                </Fab>
            )}
            {includePlans && (
                <Fab
                    variant="extended"
                    size="large"
                    className={classes.action}
                    color="secondary"
                    onClick={() => navigate('/product/plans')}
                    aria-label="plans"
                >
                    {/* <NavigationIcon className={classes.extendedIcon} /> */}
                    Plans &amp; Pricing
                </Fab>
            )}
        </div>
    )
}

export default withStyles(styles)(TryItAction)
