import React from 'react'
import Consent1 from './consent-cartoon1.png'
import Consent2 from './consent-cartoon2.png'
import Consent3 from './consent-cartoon3.png'
import Consent4 from './consent-cartoon4.png'
import { Carousel } from '@signatu/common-react'

export default () => (
    <Carousel>
        <img src={Consent1} />
        <img src={Consent2} />
        <img src={Consent3} />
        <img src={Consent4} />
    </Carousel>
)
